.CircularProgressbar{
  width: 60px !important;
}


.Img{
  text-align: center;
  margin-bottom: 10px;

}
.Main{
  display: flex;
  flex-direction: column;
    background-color: lightgray;
}
.Page,.FirstMid,.SecondMid,.ThirdMid{
  background-color: white;
  width: 52.5%;
  height: 130vh;
  text-align: center;
align-items: center;
margin-left: auto;
margin-right: auto;
display: block;
flex-direction: column;
padding: 3px;
margin-bottom: 10px;

}
.para,.p{
  background-color: white;
  padding: 3px;
}
.count{
  width: 50px;
  height: 50px;
  float: right;
  background-color: white;
  
}

.NinthMid{
  background-color: white;
  width: 52.5%;
  height: 140vh;
  text-align: center;
align-items: center;
margin-left: auto;
margin-right: auto;
display: block;
flex-direction: column;
padding: 3px;
margin-bottom: 10px;

}

.SeventhMid{
  background-color: white;
  width: 52.5%;
  height: 170vh;
  text-align: center;
align-items: center;
margin-left: auto;
margin-right: auto;
display: block;
flex-direction: column;
padding: 3px;
margin-bottom: 10px;

}

.firstpara{
  float: left;
  margin-left: 60px;
  margin-bottom: 10px;
  background-color: white;
color:rgb(168, 195, 246)
}

.HeadingCharts{
  float: left;
  margin-left: 60px;
  margin-bottom: 10px;
  background-color: white;
  font-size: 20px;
  
  
}

.Digitalpara{
  float: left;
  margin-left: 60px;
  margin-bottom: 10px;
  background-color: white;
  font-size:20px;
}

.copyRight1,.copyRight2{
  float: left;
  background-color: white;
margin-left: 50px;
  margin-top: 220px;
}
.LeftCopyRight1,.LeftCopyRight2{
  background-color: white;
  margin-left: 20px;
  font-size:small;

}
.midCopytRight1,.midCopytRight2{
  background-color: white;
  margin-left: 120px;
  font-size:small;
}

.para2,.p2{
  background-color: white;
  padding: 3px;
  text-align: left;
margin-left: 40px;

}

.copyRight2{
  float: left;
  background-color: white;
margin-left: 50px;
  margin-top: 110px;
}
.LeftCopyRight2{
  background-color: white;
  margin-left: 30px;
  font-size:small;

}
.midCopytRight2{
  background-color: white;
  margin-left: 140px;
  font-size:small;
}
.ThirdMid{
  background-color: white;
  width: 52.5%;
  height: 140vh;
  text-align: center;
align-items: center;
margin-left: auto;
margin-right: auto;
display: block;
flex-direction: column;
padding: 3px;
margin-bottom: 10px;

}

.FourthMid{
  background-color: white;
  width: 52.5%;
  height: 140vh;
  text-align: center;
align-items: center;
margin-left: auto;
margin-right: auto;
display: block;
flex-direction: column;
padding: 3px;
margin-bottom: 10px;

}
.FifthMid{
  background-color: white;
  width: 52.5%;
  height: 180vh;
  text-align: center;
align-items: center;
margin-left: auto;
margin-right: auto;
display: block;
flex-direction: column;
padding: 3px;
margin-bottom: 10px;

}
.TenthMid{
  background-color: white;
  width: 52.5%;
  height: 160vh;
  text-align: center;
align-items: center;
margin-left: auto;
margin-right: auto;
display: block;
flex-direction: column;
padding: 3px;
margin-bottom: 10px;

}


.EighthMid{
  background-color: white;
  width: 52.5%;
  height: 170vh;
  text-align: center;
align-items: center;
margin-left: auto;
margin-right: auto;
display: block;
flex-direction: column;
padding: 3px;
margin-bottom: 10px;

}


.copyRight3{
  float: left;
  background-color: white;
margin-left: 50px;
  margin-top: 110px;
}

.para3,.p3{
  background-color: white;
  padding: 3px;
  text-align: left;
  margin-right: 20px;
margin-left: 40px;

}
.strng{
  background-color: white;

}


.para4{
  background-color: white;
  padding: 3px;
margin-left: 40px;
}

.Orderedlist,.ol1,.li1,.ul1{
  text-align: left;
  background-color: white;
  line-height: 1.4;
  width: 250px;
  margin-left: 0px;
}



.OrderedlistRight{
  float: right;
  background-color: white;

  line-height: 1.4;
  width: 250px;
  margin-left: 90px;
}

.OrderedlistMain{
  display: flex;
  flex-direction: row;
  background-color: white;
 margin-right: 10px;

}

.hrR{
 height: 2px;
 background-color: #328eb2;
 margin:10px 0px;
}

.hrPara{
  background-color: white;
/*color:rgb(168, 195, 246)*/
color:#328eb2;
    border-bottom: 5px solid #328eb2;
    border-top: 5px solid #328eb2;
    padding: 5px 0px;
    font-style: italic;

}
p.hrBottomContainer{
	    border-bottom: 5px solid #328eb2;
    border-top: 5px solid #328eb2;
    padding: 5px 0px;
}
.trcontainer tr{}
.hrBottom{
  background-color: white;

}

.copyRight4{
  float: left;
  background-color: white;
margin-left: 50px;
  margin-top: 120px;
}

.copyRight5{
  float: left;
  background-color: white;
margin-left: 50px;
  margin-top: 60px;
}
.copyRight6{
  float: left;
  background-color: white;
margin-left: 50px;
  margin-top: 280px;
}

.bottomBtn{
  color: white;
  background-color: rgb(24, 153, 204);
  width: 300px;
  height: 40px;
  border: none;
  border-radius: 7px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.main10thDiv{
  display: flex;
  flex-direction: row;
  background-color: white;

}
.copyRight7{
  float: left;
  background-color: white;
margin-left: 50px;
  margin-top: 150px;
}

.table{
  width: auto;
  background-color: white;
  height: 200px;
}
.table, tr{
  
  margin-right: 10px;
}
.trs{
  width: 90%;
  background-color: white;

}
table,tr,th,td,strong{
  font-weight: small;
  font-size: small;
  background-color: white;

}

.copyRight8{
  float: left;
  background-color: white;
margin-left: 50px;
  margin-top: 310px;
}

.copyRight9{
  float: left;
  background-color: white;
margin-left: 50px;
  margin-top: 540px;
}

.chartsMain{
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  display: block;
  z-index: 1;
  background-color: white;

}
.Barchart,.HorBar,.PieChart{
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  border: 4px;
  background-color: white;

  display: block;
}

/*REPORT HEADER STYLING*/
.standardReportHeader>div{
    background-color: white;
    justify-content: space-between;
    display: flex;
    padding: 5px 10px;
	border-bottom: 1px solid deepskyblue;
}
.standardReportHeader .headerLogo{
	height:70px;
	width:60px;
}
.standardReportHeader .headerTitle{
	background-color: white;
    display: block;
    margin-top: 25px;
    text-align: center;
}
.standardReportHeader .headerDate{
	vertical-align: middle;
    margin-top: 25px;
}
.standardReportFooter{
	margin-top:auto;
}
.standardReportFooter>div{
	    background-color: white;
    justify-content: space-between;
    display: flex;
    padding: 10px 20px;
	border-top: 2px solid #d4f1ff;
}
.pageInnerContainer{
    border: 4px solid #d4f1ff;
    margin: 20px;
	height: calc( 100% - 40px );
	display: flex;
    flex-direction: column;
}
.heading2{
	font-size: 20px;
    float: none !important;
    margin: 15px 0px;
	text-align:left!important;
}
.pageInnerContainer tr.alignTextTop td{vertical-align: top;}
.pageInnerContainer tr td{font-style:italic;}
.pageInnerContainer .trcontainer td{font-size:12.5px;}
.themeColor{
	color:#2b6e8c;
}
.themeColor2{
	color:#328eb2;
}